<template>
  <div class="group">
    <v-card
      :ripple="false"
      class="card"
      variant="flat"
      @click.stop="handleClick"
    >
      <v-img
        class="!rounded-lg filter-none h-[164px] md:h-[216px] lg:h-[256px]"
        :src="product?.image || '/img/default-lazy-load.jpg'"
        :alt="product?.name"
        cover
      />
      <SharedNextDayIcon
        v-if="product.next_day"
        class="!top-[10px] !left-[10px]"
      />
      <div class="card-info">
        <div class="card-title leading-[1.375rem] min-h-[2.75rem] font-inter text-[#1d1d1d] !line-clamp-2">
          {{ product?.name }}
        </div>
        <div class="card-cost ml-[16px]">{{ product?.price.toLocaleString() }}&nbsp₸</div>
      </div>
      <v-card-actions class="!block lg:!hidden lg:group-hover:!block">
        <BaseButton
          v-if="showRequestModalButton"
          variant="main"
          :text="$t(buttonName)"
          @click="openRequestModal"
        />
        <BaseButton
          v-else
          variant="main"
          :disabled="isButtonDisabled"
          :text="$t(buttonName)"
          @click.stop="selectProduct(product)"
        />
      </v-card-actions>
    </v-card>
    <TTProductModal
      :is-open="isOpen"
      v-if="isOpen"
      :product="product"
      @close-handler="isOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import { useIndexStore } from '~/stores'
import { useCartStore } from '~/stores/cart'

const props = defineProps<{
  product: Product
  multiple?: boolean
}>()

const localePath = useLocalePath()
const indexStore = useIndexStore()
const cartStore = useCartStore()

const isOpen = ref(false)
const selectedIndex = ref(0)
const { notify } = useToast()

const selectProduct = (value: Product) => {
  cartStore.setProductToBasket(value).then(() => {
    notify({
      type: 'success',
      text: `Ваш продукт ${props.product.name} успешно добавлен в корзину`,
    })
  })
}
const isButtonDisabled = computed(() => !!props.product.is_onlyview)
const selectedProduct = computed(() => {
  return props.multiple ? props.product[selectedIndex.value] : props.product
})
const city = useCityCookie().get()
const showRequestModalButton = computed(
  () =>
    (city.name === 'Астана' && selectedProduct.value.is_request) ||
    city.name !== 'Астана'
)
const buttonName = computed(() => {
  switch (true) {
    case !!props.product.is_onlyview:
      return 'home_list_btn_special_product'
    case !!props.product.is_special:
      return 'home_list_btn_only_view_product'
    case !!props.product.is_request:
      return 'home_list_btn_request_product'
    case !!props.product.next_day:
      return 'home_list_btn_next_day_product'
    default:
      return 'home_list_btn_add2cart'
  }
})
const isMobileDevice = () => window.innerWidth <= 768

function handleClick() {
  if (isMobileDevice()) {
    navigateTo(
      localePath(
        `/${indexStore.current_county?.slug}/product/${props.product.id}`
      )
    )
  } else {
    isOpen.value = true
  }
}
</script>

<style scoped lang="postcss">
.card {
  @apply transition-all ease-in-out hover p-0;
}
.card-info {
  @apply flex flex-row justify-between my-4 mx-0 h-[34px];
}
.card-title {
  @apply font-medium text-base leading-4;
}
.card-cost {
  @apply font-semibold text-[18px] leading-4;
}
.hover {
  @apply lg:group-hover:rounded-lg lg:group-hover:border-main lg:group-hover:border lg:group-hover:z-[1001] lg:group-hover:w-full lg:group-hover:absolute lg:group-hover:p-4;
}
</style>
