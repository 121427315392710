<template>
  <div class="md:mb-32 mb-20 relative">
    <v-row>
      <v-col cols="12" class="pt-0">
        <TTCarousel />
      </v-col>
    </v-row>

    <v-row v-if="newProductList?.length" class="mt-16">
      <v-col cols="12">
        <h2
          class="text-xl mb-[21px] md:mb-4 lg:mb-5 md:text-large font-semibold"
        >
          {{ $t('home_views_novelty_products') }}
        </h2>
      </v-col>
    </v-row>

    <div v-if="!isLoading">
      <div
        v-if="newProductList?.length"
        class="grid mb-20 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-8 gap-y-6"
      >
        <div
          v-for="product in newProductList"
          :key="product.id"
          class="relative h-[280px] md:h-[340px] lg:h-[392px]"
        >
          <TTProductCard :product="product" class="group" />
        </div>
      </div>

      <v-row v-if="!!categoryList?.length" class="categories">
        <v-col id="categories" cols="12">
          <v-tabs
            v-model="currentCategory"
            align-tabs="start"
            :height="32"
            mandatory
            color="main"
          >
            <v-tab
              v-for="category in categoryList"
              :key="category.id"
              class="nav-bar__tab min-w-0 font-semibold tracking-normal px-0 text-dark-gray text-[16px]"
              :value="category.id"
              @click="selectCategory()"
            >
              {{ category.name }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <h2
        id="selected-category-name"
        style="scroll-margin-top: 100px"
        class="text-xl mb-[21px] md:mb-4 lg:mb-5 mt-8 md:text-large font-semibold"
      >
        {{ selectedCategory?.name }}
      </h2>
      <div
        v-if="productList?.length"
        id="products"
        class="grid grid-cols-2 mt-4 md:grid-cols-3 lg:grid-cols-4 gap-x-8"
      >
        <div v-for="product in productList" :key="product.id" class="relative h-[280px] lg:h-[310px]">
          <TTProductCard :product="product" />
        </div>
      </div>
      <div v-else-if="productList?.length === 0" class="no-products">
        <h2 class="text-center pa-5">нету данных</h2>
      </div>
    </div>
    <div v-else class="no-products flex items-center justify-center">
      <SharedSpinner />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useProductStore } from '~/stores/product'

const { t } = useI18n()

const productStore = useProductStore()

const currentCategory = ref(0)

const isLoading = ref(true)
const categoryList = computed(() => productStore?.totalData?.categories)
const productList = computed(() => {
  const activeProducts = productStore.totalData?.categories.find(
    (ell) => ell.id === currentCategory.value
  )
  if (activeProducts) {
    return activeProducts.products
  } else {
    return productStore.totalData?.categories[0].products
  }
})
const newProductList = computed(() => productStore.newProductList)

const selectedCategory = computed(() =>
  categoryList.value?.find((ell) => ell.id === currentCategory.value)
)
function selectCategory() {
  document.getElementById('selected-category-name')?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  })
}

const { data, pending, error } = await useAsyncData(
  'productList',
  () => productStore.fetchProductList(),
  {
    server: true,
    lazy: false,
  }
)
isLoading.value = false

useSeoMeta({
  title: t('home_views_title_meta'),
  description: t('home_views_description_meta'),
  ogTitle: t('home_views_og_title_meta'),
  ogDescription: t('home_views_og_description_meta'),
})
</script>

<style scoped lang="scss">
.categories {
  position: sticky !important;
  top: 0 !important;
  z-index: 1005 !important;
  background-color: rgb(var(--v-theme-theme-white));
}
.no-products {
  height: 500px;
}
</style>
<style>
.v-slide-group__content {
  justify-content: space-between !important;
}
</style>
