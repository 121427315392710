<template>
  <v-dialog v-model="props.isOpen" width="950" persistent>
    <v-card class="modal relative" height="700">
      <v-card-actions class="modal-actions">
        <VSpacer />
        <VBtn
          class="!absolute top-0 right-0"
          icon="mdi mdi-window-close"
          @click="closeDialog"
        />
      </v-card-actions>
      <v-row>
        <v-col :span="12" class="flex flex-col justify-between">
          <div>
            <VImg
              class="rounded-[8px]"
              cover
              :alt="product.name"
              lazy-src="/img/default-lazy-load.jpg"
              :src="product?.image"
            />
            <VImg
              class="modal-img__small"
              cover
              :alt="product.name"
              lazy-src="/img/default-lazy-load.jpg"
              :src="product?.image"
            />
          </div>
          <div>
            <NuxtLinkLocale
              :to="`/${indexStore.country?.slug}/product/${product.id}`"
              class="text-main"
            >
              {{ $t('modal-container_details') }}
            </NuxtLinkLocale>
          </div>
        </v-col>
        <v-col :span="12">
          <div class="modal-info">
            <div style="min-height: 290px">
              <div>
                <v-card-title class="modal-info__title !text-[32px]">{{
                  product?.name
                }}</v-card-title>
                <v-card-text
                  class="modal-info__desc !font-inter"
                  v-html="product?.description"
                />
              </div>
              <div>
                <v-card-title v-if="product?.info">
                  <h4 class="!font-inter text-base">{{ $t('main_vpopup_composition_title') }}</h4>
                </v-card-title>
                <v-card-text
                  v-if="product?.info"
                  class="!font-inter"
                  v-html="product?.info"
                />
              </div>
            </div>
            <div>
              <div class="flex justify-between">
                <v-card-title
                  ><h5 class="!font-inter">{{ $t('main_vpopup_sum_title') }}</h5></v-card-title
                >

                <v-card-title class="text-end">
                  <h4>{{ currentPrice.toLocaleString() }}&nbsp₸</h4>
                </v-card-title>
              </div>
              <div class="flex flex-col">
                <div class="grid gap-4 grid-cols-3 p-3">
                  <div v-if="!product.is_onlyview" class="modal-price__counter">
                    <button
                      class="modal-price__counter__btn"
                      @click="minusCounter"
                    >
                      -
                    </button>
                    <span class="modal-price__counter__count">
                      {{ amount }}
                    </span>
                    <button
                      class="modal-price__counter__btn"
                      @click="plusCounter"
                    >
                      +
                    </button>
                  </div>
                  <BaseButton
                    v-if="showRequestModalButton"
                    class="col-span-2"
                    style="border: 1px solid"
                    :text="$t(buttonName)"
                    variant="main"
                    @click="openRequestModal"
                  />
                  <BaseButton
                    v-else
                    :class="!product.is_onlyview ? 'col-span-2' : 'col-span-3'"
                    class="col-span-2"
                    style="border: 1px solid"
                    variant="main"
                    :disabled="isButtonDisabled"
                    :text="$t(buttonName)"
                    @click="addToBasket"
                  />
                  <BaseButton
                    v-if="!isButtonDisabled && !showRequestModalButton"
                    class="col-span-2 col-start-2"
                    style="border: 1px solid; height: 42px"
                    variant="main"
                    :text="$t('main_vpopup_btn_buy2direct')"
                    @click="createOrderImmediately"
                  />
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { useIndexStore } from '~/stores'
import { useCartStore } from '~/stores/cart'
import { useRequestModalStore } from '~/stores/requestModal'

const props = defineProps<{
  isOpen?: boolean
  product: Product
}>()
const emit = defineEmits<{
  (e: 'closeHandler'): void
}>()

const localePath = useLocalePath()
const indexStore = useIndexStore()
const cartStore = useCartStore()
const requestModalStore = useRequestModalStore()

const amount = ref(1)
const requestModal = ref(true)
const price = ref(props.product?.price)
const currentPrice = ref(props.product?.price)

function closeDialog() {
  emit('closeHandler')
  amount.value = 1
}
function minusCounter() {
  if (amount.value > 1) {
    amount.value--
  }
  calculateSum()
}

function plusCounter() {
  amount.value++
  calculateSum()
}
const buttonName = computed(() => {
  switch (true) {
    case !!props.product.is_onlyview:
      return 'home_list_btn_special_product'
    case !!props.product.is_special:
      return 'home_list_btn_only_view_product'
    case !!props.product.is_request:
      return 'home_list_btn_request_product'
    case !!props.product.next_day:
      return 'home_list_btn_next_day_product'
    default:
      return 'home_list_btn_add2cart'
  }
})
function openRequestModal() {
  requestModalStore.SET_SHOW_REQUEST_ORDER_MODAL(true)
}
const showRequestModalButton = computed(() => {
  if (process.client) {
    const city = useCityCookie().get()
    return (
      (city.name === 'Астана' && props.product.is_request) ||
      city.name !== 'Астана'
    )
  }
  return false
})

function addToBasket() {
  const data = {
    ...props.product,
    amount: amount.value,
  }
  cartStore.setProductToBasket(data, amount.value).then(() => {
    emit('closeHandler')
  })
}

function createOrderImmediately() {
  navigateTo(localePath(`/${indexStore.current_county?.slug}/order`))
  addToBasket()
}

const isButtonDisabled = computed(() => {
  return Boolean(props.product.is_onlyview)
})
const isImmediatelyOrder = computed(() => {
  return !isButtonDisabled.value && requestModal.value
})
function calculateSum() {
  currentPrice.value = price.value * amount.value
}
onMounted(() => {
  const city = useCityCookie().get()
  if (city) {
    requestModal.value =
      (city.name === 'Астана' && props.product.is_request) ||
      city.name !== 'Астана'
  }
})
</script>

<style scoped lang="scss">
.modal {
  padding: 0 40px;
  overflow: hidden;
  @media (max-width: 768px) {
    padding: 0 20px;
  }

  &-info {
    gap: 1rem;
    display: grid;

    &__title {
      font-weight: 600;
      font-size: 1.2rem;
    }

    &__desc {
      font-size: 14px;
    }
  }
  &-more {
    margin-left: 30px;
    color: rgb(var(--v-theme-main));
    font-weight: 600;
    cursor: pointer;
  }

  &-price {
    &__counter {
      border: 1px solid #989898;
      display: flex;
      align-items: center;
      max-width: 100px;
      padding: 8px 0;
      border-radius: 8px;

      &__btn {
        height: 1.5rem;
        color: #989898;
        font-size: 1rem;
        font-weight: 600;
        width: 2rem;
      }

      &__count {
        padding: 0 1rem;
        font-weight: 600;
        margin-bottom: -2px;
      }
    }

    &-basket {
      &__btn {
        width: 100%;
        height: 40px;
        border-radius: 8px;
        margin-left: -20px;
      }
    }

    &-confirm {
      &__btn {
        width: 100%;
        height: 40px;
        border-radius: 8px;
      }
    }
  }

  &-actions {
    height: 25px;
  }

  &-img {
    border-radius: 8px;
    margin-left: 1rem;
    height: 400px;

    &__small {
      width: 4.125rem;
      height: 4.5rem;
      margin-top: 1rem;
      cursor: pointer;
      border-radius: 8px;
    }
  }
}
</style>
