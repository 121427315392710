<template>
  <v-carousel
    height="100%"
    hide-delimiter-background
    class="carousel__wrapper mt-[40px]"
    color="white"
  >
    <template #prev="{ props }">
      <button class="carousel__button_left" @click="props.onClick"></button>
    </template>
    <template #next="{ props }">
      <button class="carousel__button_right" @click="props.onClick"></button>
    </template>
    <v-carousel-item
      width="100%"
      alt="banner image"
      aspect-ratio="2.5"
      src="/img/b1-1.png"
    />
    <v-carousel-item
      width="100%"
      alt="banner image"
      aspect-ratio="2.5"
      src="/img/b2t.png"
    />
  </v-carousel>
</template>

<script setup lang="ts">
const carouselItems = ref([])
onMounted(async () => {
  const city = useCityCookie().get()
  await useApi()
    .$get('/carousel', {
      params: {
        city_id: city.id,
      },
    })
    .then((response) => {
      carouselItems.value = response
    })
})
</script>

<style scoped lang="scss">
.carousel {
  &__wrapper {
    position: relative;
    border-radius: 8px;
  }

  &__button {
    &_left {
      position: absolute;
      width: 50%;
      height: 100%;
      left: 0;
    }

    &_right {
      position: absolute;
      width: 50%;
      height: 100%;
      right: 0;
    }
  }
}
</style>
<style>
.v-carousel__controls {
  display: none !important;
}
</style>
